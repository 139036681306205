<template>
    <div>
        <div class="content-query">
            <Query @getObtener="getObtener"></Query>
        </div>

        <div class="mt-2">
            <Table :productos="products"></Table>
        </div>

        <div v-if="loading == true" class="container-custom">
            <ProgressSpinner />
        </div>

        <div class="mt-2" v-if="products.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="getObtener" :props="true"></Paginator>
        </div>
    </div>
</template>
<script>
import Query from "@/components/Query/QueryTableProductos.vue";
import Table from "@/components/Tables/TableProductos_table.vue";
import Paginator from "@/components/Paginator.vue";
import ProgressSpinner from "primevue/progressspinner";
//Services

import { ProductsService } from "@/services/Taxonomia/ProductsService.js";

export default {
    components: {
        Query,
        Table,
        Paginator,
        ProgressSpinner,
    },

    data() {
        return {
            products: [],
            loading: false,

            total_dados: 0,
            total_pages: 0,

            current_page: 1,

            props_request: null,

            links: [],
        };
    },

    methods: {
        //Recebe os dados do query e passa para o data
        getObtener(value, page) {
            if (!value) {
                value = this.props_request;
            } else {
                this.props_request = value;
            }

            page = page ? page : 1;

            var brand_id = value.brand;
            var nombre = value.nombre;

            console.log(value);

            var categorie_id = nombre.category_id;
            var features = [];
            var name = nombre.name;
            var country = [nombre.taxonomy_product.country];
            var retails = [nombre.taxonomy_product.retail];
            var unique = true;

            for (var i = 0; i < 5; i++) {
                if (nombre["feature_" + i]) {
                    features.push(nombre["feature_" + i].id);
                }
            }

            this.loading = true;

            ProductsService.getNameUnique(
                retails,
                [categorie_id],
                country,
                [brand_id],
                page,
                name,
                features,
                unique
            )
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }

                    var id = 0;

                    this.products = [];

                    this.total_dados = result.data.total;

                    this.total_pages = result.data.total_pages;

                    this.currente_page = result.data.current_page;

                    result.data.data.map((item) => {
                        item.id_ = id;
                        id++;

                        item.links_open = false;

                        item.edit = true;

                        item.disableds = {
                            nombre: true,
                            brand: true,
                            categoria: true,
                            features: true,
                            btn_agregar: true,
                        };

                        if (id % 2 == 0) {
                            item.class = "linea_gris";
                        } else {
                            item.class = "linea_white";
                        }

                        var id_items = 0;
                        //Array map para definir uma cor dentro de items - 'producto abierto'
                        item.items.map((item) => {
                            id_items++;
                            console.log(id_items);

                            if (id_items % 2 == 0) {
                                item.class = " linea_gris";
                            } else {
                                item.class = " linea_white";
                            }
                        });

                        item.suggestions = {
                            brand: [],
                            nombre: [],
                            categoria: [],
                            features: {
                                feature_1: [],
                                feature_2: [],
                                feature_3: [],
                                feature_4: [],
                                feature_5: [],
                            },
                        };

                        item.name_status = true;

                        this.products.push(item);
                    });


                    this.loading = false;
                })
                .catch((err) => { });
        },
    },
};
</script>
