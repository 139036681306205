<template>
    <div class="query_table_productos content-query">
        <AutoComplete v-model="brand_query" field="name"
            class="custom-auto-complete max-width mr-2 custom-input-max-min-300" placeholder="Marcas"
            @complete="searchBrand" @item-select="selectBrand" :dropdown="true" :suggestions="suggestions.brand"
            :disabled="disableds.brand" />
        <AutoComplete v-model="nombre_query" :suggestions="suggestions.nombre" field="name"
            class="custom-auto-complete max-width mr-2 custom-input-max-min-300" placeholder="Nombre producto"
            @complete="searchNombre" :dropdown="true" :disabled="disableds.nombre" />

        <Button label="Obtener" class="button-custom mr-2 width_50 custom-button-max-min-200" @click="parseObtener"
            iconPos="right" />
    </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";

//Services

import { BrandService } from "@/services/BrandService.js";
import { ProductsService } from "@/services/Taxonomia/ProductsService.js";

export default {
    components: {
        AutoComplete,
        Button,
    },

    data() {
        return {
            nombre_query: null,
            brand_query: null,

            suggestions: {
                brand: null,
                nombre: null,
            },

            disableds: {
                brand: false,
                nombre: true,
            },
        };
    },

    methods: {
        searchBrand(event) {
            var name = event.query;

            this.nombre_query = null;
            this.disableds.nombre = true;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.suggestions.brand = result.data;
                })
                .catch((err) => { });
        },

        selectBrand(event) {
            this.disableds.nombre = false;
        },

        searchNombre(event) {
            var name = event.query;

            var brand_id = this.brand_query.id;

            ProductsService.getNames(name, brand_id)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.suggestions.nombre = result.data;
                })
                .catch((err) => { });
        },

        parseObtener() {
            if (this.brand_query && this.brand_query.id) {
                if (this.nombre_query && this.nombre_query.new_products_id) {

                    var request_response = { brand: this.brand_query.id, nombre: this.nombre_query }
                    this.$emit(
                        "getObtener",
                        request_response
                    );
                } else {
                    this.$store.dispatch("alert_error", {
                        message: "Seleccione un nombre",
                        time: 2000,
                    });
                }
            } else {
                this.$store.dispatch("alert_error", {
                    message: "Seleccione una brand",
                    time: 2000,
                });
            }
        },
    },
};
</script>

<style>
</style>
