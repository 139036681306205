<template>
    <div>
        <div class="mt-4 paginator-custom container-flex container-center">
            <div class="box-white">
                <div class="leftArrow">
                    <div class="circle mr-2">
                        <span
                            :class="'pi pi-angle-double-left '"
                            @click="seta('left_double')"
                        ></span>
                    </div>

                    <div class="circle">
                        <span
                            :class="'pi pi-angle-left '"
                            @click="seta('left')"
                        ></span>
                    </div>
                </div>
                <div v-for="item in range" :key="item">
                    <div v-if="map_links[item - 1].active == true">
                        <span
                            @click="number_click(map_links[item - 1])"
                            class="link active"
                            >{{ map_links[item - 1].label }}</span
                        >
                    </div>

                    <div v-else>
                        <span
                            @click="number_click(map_links[item - 1])"
                            class="link inactive"
                            >{{ map_links[item - 1].label }}</span
                        >
                    </div>
                </div>
                <div class="rightArrow">
                    <div class="circle">
                        <span
                            :class="'pi pi-angle-right '"
                            @click="seta('rigth')"
                        ></span>
                    </div>
                    <div class="circle ml-2">
                        <span
                            :class="'pi pi-angle-double-right '"
                            @click="seta('rigth_double')"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="paginator_total_items">Total: {{ total_dados }}</div>
    </div>
</template>
<script>
import axios from "axios";
import { ProductsService } from "./../services/Taxonomia/ProductsService";
import { AreaService } from "./../services/AreaService";

export default {
    props: ["total_pages", "total_dados", "page_init", "props"],

    data() {
        return {
            first_page: 1,
            last_page: null,

            map_links: [],
            current_page: null,

            links_range: [], //BY LIMIT   3,4,5,6,7    "5" - IS ACTIVE CENTER

            range: [],
        };
    },

    created() {
        this.current_page = this.page_init;
        this.last_page = this.total_pages;
    },

    watch: {
        current_page(newVal) {
            this.handler();
        },
    },

    methods: {
        getRange() {
            var range = [];

            var negativo; // ANTES DO INDEX
            this.current_page; //INDEX
            var positivo; // DEPOIS DO INDEX

            //VERIFICA OS POSITIVOS ANTES DE INCREMENTAR NO ARRAY
            if (this.current_page + 1 >= this.total_pages) {
                positivo = 1;
            } else if (this.current_page + 2 >= this.total_pages) {
                positivo = 0;
            }

            if (this.current_page == this.total_pages) {
                positivo = 2;
            }

            if (positivo == 1) {
                if (this.current_page - 3 > 0) {
                    // EXISTE 3 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 3);
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 3;
                } else if (this.current_page - 2 > 0) {
                    // EXISTE 2 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 2;
                } else if (this.current_page - 1 > 0) {
                    // EXISTE 1 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 1);
                    negativo = 1;
                } else {
                    negativo = 0; //NAO TEM PAGINA ANTES DA ATUAL
                }
            } else if (positivo == 2) {
                if (this.current_page - 4 > 0) {
                    // EXISTE 4 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 4);
                    range.push(this.current_page - 3);
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 4;
                } else if (this.current_page - 3 > 0) {
                    // EXISTE 3 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 3);
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 3;
                } else if (this.current_page - 2 > 0) {
                    // EXISTE 2 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 2;
                } else if (this.current_page - 1 > 0) {
                    // EXISTE 1 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 1);
                    negativo = 1;
                } else {
                    negativo = 0; //NAO TEM PAGINA ANTES DA ATUAL
                }
            } else {
                if (this.current_page - 2 > 0) {
                    // EXISTE 2 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 2);
                    range.push(this.current_page - 1);
                    negativo = 2;
                } else if (this.current_page - 1 > 0) {
                    // EXISTE 1 PAGINA ANTERIOR A SELECIONADA
                    range.push(this.current_page - 1);
                    negativo = 1;
                } else {
                    negativo = 0; //NAO TEM PAGINA ANTES DA ATUAL
                }
            }

            range.push(this.current_page); //PAGINA SELECIONADA

            if (this.current_page + 1 <= this.total_pages) {
                // EXISTE 1 PAGINA POSTERIOR A SELECIONADA
                range.push(this.current_page + 1);

                positivo = 1; // APENAS UMA PAGINA DEPOIS DA ATUAL
                if (this.current_page + 2 <= this.total_pages) {
                    // EXISTE 2 PAGINA POSTERIOR A SELECIONADA
                    range.push(this.current_page + 2);
                    positivo = 2;

                    if (negativo == 0) {
                        if (this.current_page + 3 <= this.total_pages) {
                            // EXISTE 3 PAGINA POSTERIOR A SELECIONADA
                            range.push(this.current_page + 3);
                        }

                        if (this.current_page + 4 <= this.total_pages) {
                            // EXISTE 4 PAGINA POSTERIOR A SELECIONADA
                            range.push(this.current_page + 4);
                        }
                    } else if (negativo == 1) {
                        if (this.current_page + 3 <= this.total_pages) {
                            // EXISTE 3 PAGINA POSTERIOR A SELECIONADA
                            range.push(this.current_page + 3);
                        }
                    }
                }
            } else {
                positivo = 0; //NAO TEM PAGINA DEPOIS DA ATUAL
            }

            return range;
        },

        number_click(num) {
            this.current_page = num.label;
            if (this.props == true) {
                this.$emit("handler", null, this.current_page);
            } else {
                this.$emit("handler", this.current_page);
            }
        },

        seta(type) {
            switch (type) {
                case "left":
                    if (this.current_page > 1 && this.total_pages > 1) {
                        this.current_page -= 1;
                        if (this.props == true) {
                            this.$emit("handler", null, this.current_page);
                        } else {
                            this.$emit("handler", this.current_page);
                        }
                    }
                    break;

                case "left_double":
                    if (this.current_page > 1 && this.total_pages > 1) {
                        this.current_page = 1;
                        if (this.props == true) {
                            this.$emit("handler", null, this.current_page);
                        } else {
                            this.$emit("handler", this.current_page);
                        }
                    }
                    break;

                case "rigth":
                    if (
                        this.current_page < this.last_page &&
                        this.total_pages > 1
                    ) {
                        this.current_page++;
                        if (this.props == true) {
                            this.$emit("handler", null, this.current_page);
                        } else {
                            this.$emit("handler", this.current_page);
                        }
                    }
                    break;

                case "rigth_double":
                    if (
                        this.current_page < this.last_page &&
                        this.total_pages > 1
                    ) {
                        this.current_page = this.last_page;
                        if (this.props == true) {
                            this.$emit("handler", null, this.current_page);
                        } else {
                            this.$emit("handler", this.current_page);
                        }
                    }
                    break;
                default:
                    console.log("Internal error !!!");
            }
        },

        handler() {
            this.current_page =
                this.current_page > this.total_pages
                    ? this.total_pages
                    : this.current_page;
            this.current_page = this.current_page <= 0 ? 1 : this.current_page;

            this.map_links = [];

            for (var i = 1; i <= this.total_pages; i++) {
                this.map_links.push({
                    label: i,
                    active: i == this.current_page ? true : false,
                });
            }

            this.range = this.getRange();
        },
    },
};
</script>
