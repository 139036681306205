<template>
    <div class="table-box" v-if="productos.length > 0" @scroll="getScroll">
        <table class="tableTaxonomia editTableProductos">
            <thead class="sticky-top">
                <tr>
                    <th :class="scroll">Inputs</th>
                    <th>Brand</th>
                    <th>Nombre</th>
                    <th>Categories</th>
                    <th>Feature 1</th>
                    <th>Feature 2</th>
                    <th>Feature 3</th>
                    <th>Feature 4</th>
                    <th>Feature 5</th>
                    <th>Links</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in productos" :key="item.id">
                    <td :class="scroll">
                        <div style="display: flex">
                            <ToggleButton @change="onEdit(item)" v-model="item.edit" onLabel="Editar" offLabel="Editar"
                                class="custom-split-button width_50 mr-2" />

                            <Button label="Confirmar" :disabled="item.edit" @click="onConfirm(item)"
                                class="custom-split-button width_50" />
                        </div>
                    </td>

                    <td>
                        <AutoComplete v-model="item.brand" field="name" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.brand" :dropdown="true" :suggestions="item.suggestions.brand"
                            @complete="searchBrand($event, item)" @item-select="selectBrand($event, item)"
                            @clear="clearBrand(item)"></AutoComplete>
                    </td>
                    <td>
                        <div style="display: flex" class="custom-input">
                            <AutoComplete v-model="item.name" :suggestions="item.suggestions.nombre" class="not-icon"
                                placeholder="Nombre" :disabled="item.disableds.nombre" field="name"
                                @complete="searchName($event, item)" @item-select="selectName($event, item)"
                                @clear="clearName(item)" />
                            <Button label="Agregar" :disabled="item.disableds.btn_agregar"
                                @click="agregarNombre(item)" />
                        </div>
                    </td>
                    <td>
                        <AutoComplete v-model="item.category" field="name" placeholder="Categoria" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.categoria" :dropdown="true"
                            @complete="searchCategoria($event, item)" @clear="clearCategoria(item)"
                            @item-select="selectCategoria($event, item)" :suggestions="item.suggestions.categoria">
                        </AutoComplete>
                    </td>
                    <td>
                        <AutoComplete v-model="item.feature_1" field="name" placeholder="Feature 1" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.features" :dropdown="true"
                            :suggestions="item.suggestions.features.feature_1"
                            @complete="searchFeature($event, item, 'feature_1')"></AutoComplete>
                    </td>
                    <td>
                        <AutoComplete v-model="item.feature_2" field="name" placeholder="Feature 2" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.features" :dropdown="true"
                            :suggestions="item.suggestions.features.feature_2"
                            @complete="searchFeature($event, item, 'feature_2')"></AutoComplete>
                    </td>
                    <td>
                        <AutoComplete v-model="item.feature_3" field="name" placeholder="Feature 3" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.features" :dropdown="true"
                            :suggestions="item.suggestions.features.feature_3"
                            @complete="searchFeature($event, item, 'feature_3')"></AutoComplete>
                    </td>
                    <td>
                        <AutoComplete v-model="item.feature_4" field="name" placeholder="Feature 4" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.features" :dropdown="true"
                            :suggestions="item.suggestions.features.feature_4"
                            @complete="searchFeature($event, item, 'feature_4')"></AutoComplete>
                    </td>
                    <td>
                        <AutoComplete v-model="item.feature_5" field="name" placeholder="Feature 5" class="
                                custom-auto-complete
                                auto-complete-no-box
                                border-blue
                            " :disabled="item.disableds.features" :dropdown="true"
                            :suggestions="item.suggestions.features.feature_5"
                            @complete="searchFeature($event, item, 'feature_5')"></AutoComplete>
                    </td>
                    <td class="links">
                        <ToggleButton v-model="item.links_open" onLabel="Links abiertos" offLabel="Links cerrados"
                            class="custom-split-button" @click="toggle($event, item)" />
                        <OverlayPanel :ref="'overflow_' + item.id_">
                            <table class="overflow-table">
                                <thead>
                                    <tr>
                                        <th>Productos</th>
                                        <th>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="index in item.items" :key="index.id">
                                        <td>
                                            {{ index.taxonomy_product.product_name }}
                                        </td>
                                        <td>
                                            <a :href="index.taxonomy_product.link" target="_blank" class="link">Ver en
                                                el sitio</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </OverlayPanel>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import ToggleButton from "primevue/togglebutton";
import Chip from "primevue/chip";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

//Services

import { BrandService } from "@/services/BrandService";
import { ProductsService } from "@/services/Taxonomia/ProductsService.js";
import { CategoriaServiceABM } from "@/services/CategoriaService.js";
import { FeatureService } from "@/services/FeatureService.js";

export default {
    components: {
        AutoComplete,
        ToggleButton,
        Chip,
        Button,
        OverlayPanel,
    },

    props: ["productos"],

    data() {
        return {
            dados: [],

            scroll: "sticky-1",
        };
    },

    created() {
        console.log(this.productos)
        console.log(this.productos)
    },

    watch: {
        links(newVal) {
            console.log(newVal);
        },
    },

    methods: {
        onEdit(item) {
            if (item.edit == false) {
                item.disableds.nombre = false;
                item.disableds.brand = false;
                item.disableds.categoria = false;
                item.disableds.features = false;
            } else {
                item.disableds.nombre = true;
                item.disableds.brand = true;
                item.disableds.categoria = true;
                item.disableds.features = true;
            }
        },

        getScroll(event) {
            var scrollX = event.srcElement.scrollLeft;
            if (scrollX == 0) {
                this.scroll = "sticky-1";
            } else {
                this.scroll = "sticky-1 sticky-2-moved";
            }
        },

        handlerLinks(item, index) {
            if (item.items[index]) {
                var link = item.items[index].taxonomy_product.link;
                return link;
            } else {
                return null;
            }
        },

        searchBrand(event, data) {
            var name = event.query;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    data.suggestions.brand = result.data;
                })
                .catch((err) => { });
        },

        selectBrand(event, data) {
            data.brand_id = event.value.id;

            data.disableds.btn_agregar = false;
            data.name_status = false;
        },

        clearBrand(item) {
            item.brand_id = null;
        },

        searchName(event, item) {
            var name = event.query.toUpperCase();
            var brand = item.brand_id;

            item.disableds.btn_agregar = true; //Default

            ProductsService.getNames(name, brand)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    if (!result.data.length == 0) {
                        item.suggestions.nombre = result.data;

                        if (name.length >= 3) {
                            var check_list = false;

                            //Verifica se nas suggestions tem um objeto com o name igual ao pesquisado
                            item.suggestions.nombre.map((item) => {
                                if (item.name == name) {
                                    check_list = true;
                                }
                            });

                            if (check_list == true) {
                                item.disableds.btn_agregar = true;
                                item.disableds.categoria = true;
                                item.name_status = false;
                                if (item.category.id) {
                                    item.disableds.features = true;
                                }
                            } else {
                                item.disableds.btn_agregar = false;
                                item.disableds.categoria = false;
                                item.name_status = false;
                                if (item.category.id) {
                                    item.disableds.features = false;
                                }
                            }
                        } else {
                            item.disableds.btn_agregar = true;
                            item.name_status = false;
                            item.disableds.categoria = true;

                            if (item.category.id) {
                                item.disableds.features = true;
                            }
                        }
                    } else {
                        if (name.length >= 3) {
                            item.disableds.btn_agregar = false;
                            item.name_status = false;
                            item.disableds.categoria = false;

                            if (item.category.id) {
                                item.disableds.features = false;
                            }
                        } else {
                            item.disableds.btn_agregar = true;
                            item.name_status = false;
                            item.disableds.categoria = true;

                            if (item.category.id) {
                                item.disableds.features = true;
                            }
                        }
                    }
                })
                .catch((err) => { });
        },

        agregarNombre(item) {
            if (item.name.length > 3) {
                item.name_status = true;
                this.$store.dispatch("alert_success", {
                    message: "Nombre agregado !!!",
                    time: 2000,
                });
            } else {
                this.$store.dispatch("alert_error", {
                    message: "Ingrese un nombre con más de 3 caracteres",
                    time: 2000,
                });
            }
        },

        selectName(event, item) {
            console.log(event, item);

            item.category = event.value.category;
            item.category_id = event.value.category.id;

            item.feature_1 = event.value.feature_1;
            item.feature_2 = event.value.feature_2;
            item.feature_3 = event.value.feature_3;
            item.feature_4 = event.value.feature_4;
            item.feature_5 = event.value.feature_5;

            item.name_status = true;

            item.disableds.categoria = true;
            item.disableds.features = true;
            item.disableds.btn_agregar = true;
        },

        clearName(item) {
            item.name_status = false;
            item.disableds.btn_agregar = true;
        },

        searchCategoria(event, item) {
            var name = event.query;

            item.feature_1 = [];
            item.feature_2 = [];
            item.feature_3 = [];
            item.feature_4 = [];
            item.feature_5 = [];

            item.disableds.features = true;

            CategoriaServiceABM.getAutocomplete(name, null)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    item.suggestions.categoria = result.data;
                })
                .catch((err) => { });
        },

        selectCategoria(event, item) {
            if (event.value != null) {
                item.category_id = event.value.id;

                item.feature_1 = [];
                item.feature_2 = [];
                item.feature_3 = [];
                item.feature_4 = [];
                item.feature_5 = [];

                item.disableds.features = false;
            }
        },

        clearCategoria(item) {
            item.category = [];
            item.category_id = null;
            item.feature_1 = [];
            item.feature_2 = [];
            item.feature_3 = [];
            item.feature_4 = [];
            item.feature_5 = [];
        },

        searchFeature(event, item, field) {
            var name = event.query;

            var pos = field.split("_");
            pos = pos[1];
            console.log(pos);

            var categoria = item.category.id;
            console.log(categoria);

            if (categoria != null) {
                FeatureService.getAutocomplete(name, pos, categoria)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        item.suggestions.features[field] = result.data;
                    })
                    .catch((err) => { });
            }
        },

        onConfirm(item) {
            console.log(item);
            if (item.name_status == true) {
                if (item.brand_id) {
                    if (item.category_id) {
                        var brand_id = item.brand_id;
                        var category_id = item.category_id;
                        var name = item.name.name ? item.name.name : item.name;
                        var feature_1 =
                            item.feature_1 == null ? null : item.feature_1.id;
                        var feature_2 =
                            item.feature_2 == null ? null : item.feature_2.id;
                        var feature_3 =
                            item.feature_3 == null ? null : item.feature_3.id;
                        var feature_4 =
                            item.feature_4 == null ? null : item.feature_4.id;
                        var feature_5 =
                            item.feature_5 == null ? null : item.feature_5.id;

                        var md5 = item.md5_group;

                        ProductsService.postEditMD5_GROUP(
                            name,
                            brand_id,
                            md5,
                            feature_1,
                            feature_2,
                            feature_3,
                            feature_4,
                            feature_5,
                            category_id
                        )
                            .then((result) => {
                                const resultValidation = this.$root.resultValidationRequest(result);

                                if (resultValidation != true) {
                                    this.$store.dispatch("alert_error", {
                                        message: resultValidation,
                                        time: this.time,
                                    });

                                    return;
                                }
                                this.$store.dispatch("alert_warning", {
                                    message: "Guardado con éxito",
                                    time: 5000,
                                });
                                this.$store.dispatch("alert_warning", {
                                    message: "Brand: " + item.brand.name,
                                    time: 5100,
                                });
                                this.$store.dispatch("alert_warning", {
                                    message:
                                        "Nombre: " +
                                        (item.name.name
                                            ? item.name.name
                                            : item.name),
                                    time: 5200,
                                });

                                item.edit = true;
                                item.disableds.nombre = true;
                                item.disableds.brand = true;
                                item.disableds.categoria = true;
                                item.disableds.btn_agregar = true;
                                item.disableds.features = true;
                                item.name_status = false;
                            })
                            .catch((err) => { });
                    } else {
                        this.$store.dispatch("alert_error", {
                            message: "Seleccione una categoria.",
                            time: 2000,
                        });
                    }
                } else {
                    this.$store.dispatch("alert_error", {
                        message: "Seleccione una brand.",
                        time: 2000,
                    });
                }
            } else {
                this.$store.dispatch("alert_error", {
                    message: "No se agregó el nombre.",
                    time: 2000,
                });
            }
        },

        toggle(event, item) {
            this.productos.map((item_prod) => {
                if (item_prod.id_ != item.id_) {
                    item_prod.links_open = false;
                }
            });
            this.$refs["overflow_" + item.id_][0].toggle(event);
        },
    },
};
</script>
